import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

export default function CardLoader(props) {
    return (
        <div style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0 }}>
          <div style={{ height: '400px', width: '100%', position: 'relative', backgroundColor: 'white', marginTop: '2px', borderRadius: '5px' }}>
            <div style={{ padding: '20px' }}>
              <Skeleton animation="wave" variant="rect" height={40} />
              <Skeleton animation="wave" variant="rect" height={40} style={{ marginTop: '20px' }} />
              <Skeleton animation="wave" variant="rect" height={20} style={{ marginTop: '20px' }} />
              <Skeleton animation="wave" variant="rect" height={20} style={{ marginTop: '20px' }} />
              <Skeleton animation="wave" variant="rect" height={20} style={{ marginTop: '20px' }} />
              <Skeleton animation="wave" variant="rect" height={100} width={300} style={{ marginTop: '20px' }} />
            </div>
          </div>
        </div>
    )
}
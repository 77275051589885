import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";

// @ts-ignore
import SwipeRight from "../assets/swipe-right.png";
// @ts-ignore
import SwipeLeft from "../assets/swipe-left.png";

export const SwipeInstructions = (props) => {

    function handleClose() {
        props.onClose();
        localStorage.setItem("hasSeenSwipeInstructions", 'true');
    }


    return (
        <Dialog open={props.open}
            onBackdropClick={() => handleClose()}>
            <DialogTitle id="alert-dialog-title">Instructions</DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center', fontSize: '15px' }}>
                    <p>We've built a list of verified services within your area, based off of your preferences.</p>
                    <img src={SwipeRight} alt="Swipe Right" style={{ maxHeight: '100px' }} />
                    <p>Swipe right or tap the heart button to favorite a service.</p>
                    <img src={SwipeLeft} alt="Swipe Left" style={{ maxHeight: '100px' }} />
                    <p>Swipe left or tap the X button to hide a service.</p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    OK
          </Button>
            </DialogActions>
        </Dialog>

    )
}
import React from 'react';
import EventIcon from '@material-ui/icons/Event';

/**
 * Displays a label for a Posting
 * @param {*} props 
 * @author BrianCastor
 */
export const PostingLabel = props => {
    return (
        <div style={{ color: 'rgba(0, 0, 0, 0.54)', marginBottom: '5px' }}>
              <EventIcon style={{ fontSize: '15px', verticalAlign: 'middle', paddingRight: '5px' }} />
              <span style={{ verticalAlign: 'middle', fontSize:'13px' }}>POSTING</span>
        </div>
    )
}
import React from 'react';

//Material UI / Material UI Icons
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { globalContext } from '../../GlobalContext';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import CreateIcon from '@material-ui/icons/Create';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';



//Sub-components
import { MapView } from '../cardComponents/mapView';
import { ServicesDisplayV2 } from '../cardComponents/servicesDisplayV2';
import { DemographicsDisplay } from '../cardComponents/demographicsDisplay';
import { DistanceDisplay } from '../cardComponents/distanceDisplay';
import { VerifiedDisplay } from '../cardComponents/verifiedDisplay';
import { PhoneDisplay } from '../cardComponents/phoneDisplay';
import { WebsiteDisplay } from '../cardComponents/websiteDisplay';
import { SignupCallToAction } from '../cardComponents/signupCallToAction';

import API from '../../API';
import { LoadingDisplay } from '../loadingDisplay';
import { ErrorDisplay } from '../errorDisplay';
import { AddressDisplay } from '../cardComponents/addressDisplay';
import { PostingLabel } from './postingLabel';

import FormstackEmbedPopup from "../formstack/formstackEmbedPopup";

const FORMSTACK_FEEDBACK_FORM_URL = 'https://hopeonesource.formstack.com/forms/h1s_me_sp_feedback';

/**
 * Full detail display for an individual posting - this is used in the swiper, and when linking to an individual posting
 * @param {*} props 
 * @author BrianCastor
 */
export default function PostingDetail(props) {
  const context = React.useContext(globalContext);

  const id = props.id;
  const [posting, setPosting] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [rateServiceProviderModalOpen, setRateServiceProviderModalOpen] = React.useState(false);

  React.useEffect(() => {
    API.getPostingById(id).then(data => {
      setPosting(data);
    }).catch(err => {
      setError(err);
    })
  }, [id]);

  if (error) {
    return (<ErrorDisplay error={error}/>)
  }

  if (!posting) {
    return (<LoadingDisplay/>)
  }

  return (
    <Container maxWidth="sm" style={{ marginTop: '2px', padding: '2px' }}>
      {/*If user is not logged in, display a call to action to sign up*/}
      {!context.currentUser &&
        <SignupCallToAction serviceName={posting.AccountId__r ? (posting.AccountId__r.Name) : (null)}/>
      }
      <Card>
          {/*Small colored banner*/}
          <CardMedia component="div">
            <div style={{ width: '100%', height: '3px', background: 'repeating-linear-gradient(45deg, #c1272d, #fbb03b 15%, #f5f5f5 20%)' }}></div>
          </CardMedia>

          <CardContent>
            <PostingLabel/>
            {posting.AccountId__r ? (
              <Typography gutterBottom variant="h5" component="h2">
                {posting.Drupal_HOS_Post_Title__c}
              </Typography>) : ''}
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
            >

              <div style={{ padding: '5px 0px' }}>
                <BusinessOutlinedIcon style={{ verticalAlign: 'middle', fontSize: '17px', paddingRight: '5px' }} /><span>{posting.AccountId__r ? posting.AccountId__r.Name : 'No service provider information given'}</span>
              </div>
              <DistanceDisplay currentLocation={context.currentUser ? context.currentUser.Location__c : null} destination={posting.Location__c} />
              <VerifiedDisplay dateVerified={posting.Original_Post_Date__c} />


              <p><strong>Service/Event Date(s):</strong> {posting.Service_Event_Date_s__c ? posting.Service_Event_Date_s__c.toString().split('.')[0] : 'No dates given'}</p>
              <p><strong>Services</strong></p>
              <ServicesDisplayV2 services={posting.Services__r} />

              <p><strong>Communities Served</strong></p>
              <DemographicsDisplay Demographics={posting.Demographics_MPL__c} />

              <p><strong>Service Provider Information</strong></p>
              {posting.AccountId__r ?
                (<div>
                  <div style={{ padding: '5px 0px' }}>
                    <BusinessOutlinedIcon style={{ verticalAlign: 'middle', fontSize: '17px', paddingRight: '5px' }} /><span>{posting.AccountId__r.Name}</span>
                  </div>
                  <PhoneDisplay number={posting.AccountId__r.Phone} />
                  <WebsiteDisplay url={posting.AccountId__r.Website} />
                </div>)
                :
                (<span>No service provider information given</span>)}

              <Divider/>
              {/*Write a review button - this links back to the account*/}
              <div style={{paddingTop:'15px', paddingBottom:'15px'}}>
                <Button color="primary" variant="contained" startIcon={<CreateIcon/>} onClick={() => setRateServiceProviderModalOpen(true)}>Write a Review</Button>
              </div>
              <Divider/>

              <p><strong>Location</strong></p>
              <AddressDisplay address={posting.ShippingAddress}/>
              <MapView coordinates={posting.Location__c} />

            </Typography>
          </CardContent>
      </Card>
      <FormstackEmbedPopup url={FORMSTACK_FEEDBACK_FORM_URL} prepopulate={{'ContactID':context.currentUser && context.currentUser.Id, 'AccountID':posting.AccountId__c}} open={rateServiceProviderModalOpen} onSubmit={() => setRateServiceProviderModalOpen(false)}/>
    </Container>
  )
}

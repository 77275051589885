import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Swiper from "../components/swiper"
import Container from '@material-ui/core/Container'

const SwipePage = (data) => (
  <Layout>
    <Seo title="Swipe" />
    <Container maxWidth="sm" style={{ paddingTop: '10px' }}>
      <Swiper />
    </Container>
  </Layout>
)

export default SwipePage;
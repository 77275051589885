import React from "react";

//Material UI / Material UI Icons
import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby";

import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import Button from "@material-ui/core/Button";

//Global data store
import { globalContext } from '../GlobalContext'

//Child Components
import PostingDetail from './postings/postingDetail'
import AccountDetail from './accounts/accountDetail'
import CardLoader from './cardComponents/cardLoader'

//Card swiping features
import SwipeableViews from 'react-swipeable-views';

//Utilities
import {getTypeOfSalesforceRecord} from '../utils';
import API from '../API';
import { ErrorDisplay } from "./errorDisplay";
import { LoadingDisplay } from "./loadingDisplay";
import { SwipeInstructions } from "./swipeInstructions";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import FavoriteTwoToneIcon from '@material-ui/icons/FavoriteTwoTone';
import Container from "@material-ui/core/Container";
/**
 * Display 'swiper' (think Tinder) to swipe left/right on postings & service providers to hide/favorite them
 * @param {*} props 
 * @author BrianCastor
 */
export default function Swiper(props) {
  const context = React.useContext(globalContext);
  const [matches, setMatches] = React.useState([]);

  //Alert popup
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertType, setAlertType] = React.useState('success');
  const [alertMessage, setAlertMessage] = React.useState('');

  //Instructions popup
  const [showInstructions, setShowInstructions] = React.useState(typeof window !== 'undefined' && localStorage.getItem("hasSeenSwipeInstructions") ? false : true);

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    //Ask for location on page load
    getLocation();
  }, []);

  React.useEffect(() => {
    //Generate matches on page load and whenever user data changes
    setLoading(true)
    API.generateMatches(context.currentUser, context.favoritedPostingIds, context.rejectedPostingIds).then(matches => {
      setMatches(matches);
      setLoading(false);
    }).catch(err => {
      setError(err);
      setLoading(false);
    })
  }, [context.currentUser]);

  //Redirect to / if user is not signed in
  if (!context.isRegistered()) {
    context.navigateSafe('/');
    return (null);
  }

  if (loading) {
    return (<LoadingDisplay />)
  }

  if (error) {
    return (<ErrorDisplay error={error} />)
  }

  /**
   * Access the user's location (via their web browser) to 
   */
  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        context.updateUserPreferences({
          'Location__c': {
            'latitude': position.coords.latitude,
            'longitude': position.coords.longitude,
          }
        })
      }, (err) => {
        console.log(err);
      });
    }
  }

  async function onSwipe(toIndex, option_id, recordType) {

    if (toIndex === 0) {
      //Swiped right - Favorite
      setShowAlert(true);
      setAlertType('success');
      setAlertMessage('Favorited');
      setMatches(matches.slice(1));

      //Update local storage and run API request to log to API, but don't wait (improve swipe performance). Add 'await' to wait for this to complete
      context.favoritePosting(option_id, recordType);
    }

    if (toIndex === 2) {
      //Swiped left - Reject
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Hidden');
      setMatches(matches.slice(1));

      //Update local storage and run API request to log to API, but don't wait (improve swipe performance). Add 'await' to wait for this to complete
      context.rejectPosting(option_id, recordType);
    }
  }

  function handleAlertClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setShowAlert(false);
    setAlertMessage(null);
  };

  const cardDisplay = (card) => {
    const recordType = getTypeOfSalesforceRecord(card);
    return (
      <React.Fragment key={card.Id}>
        {/*Loading card backdrop (so when you're swiping, you see a 'next' card)*/}
        <CardLoader />
        {/*Swipeable card - https://react-swipeable-views.com/api/api/*/}
        <div key={card.Id} style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0 }}>
          <SwipeableViews enableMouseEvents ignoreNativeScroll index={1} onChangeIndex={(index, indexLatest, meta) => onSwipe(index, card.Id, recordType)}>
            <div>{/*Dummy div for favoriting*/}</div>
            {/*Card to show - if its an account, show Account detail, else show Posting Detail*/}
            {recordType === 'Account' ?
              (<AccountDetail id={card.Id} abbreviated={true}></AccountDetail>)
              :
              (<PostingDetail id={card.Id} abbreviated={true}></PostingDetail>)
            }
            <div>{/*Dummy div for rejecting*/}</div>
          </SwipeableViews>
          {/*Spacer so things don't get cut off*/}
          <div style={{ height: '150px', margin: '0 0 -150px 0' }}></div>
        </div>
      </React.Fragment>
    )
  }



  return (
    <div style={{ paddingBottom: '100px'}}> 
      <Grid
        container
        spacing={0}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          container
          spacing={0}
          xs={12}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <div style={{ position: "relative", width: "100%" }}>
            {
              matches[0] ? (
                cardDisplay(matches[0])
              )
                :
                //Empty state
                (
                  <div style={{ textAlign: 'center', width: '100%', marginTop: '50px' }}>
                    <FilterNoneIcon style={{ fontSize: 80, color: 'grey' }} />
                    <h3 style={{ color: 'grey' }}>No new service providers or postings found in your area</h3>
                    <p style={{ color: 'grey' }}>Try adjusting your preferences, viewing your likes, or resetting your hidden postings on the Profile page, or check back at a later time.</p>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to="/profile">
                      Update Preferences
                  </Button>
                  </div>
                )
            }
          </div>
        </Grid>
      </Grid>
      {matches[0] ? (
        <div style={{ position: 'fixed', bottom: 0, marginLeft: 'auto', marginRight: 'auto', left: 0, right: 0, textAlign: 'center', backgroundColor: 'white', paddingBottom:'3px', paddingTop:'3px', border:'1px solid lightgrey' }}>
          <Container maxWidth="sm">
          <Grid container spacing={3} alignItems="center" justify="space-around">
            <Grid item xs></Grid>
            <Grid item xs={7}>
              <div 
                onClick={() => onSwipe(2, matches[0].Id, getTypeOfSalesforceRecord(matches[0]))}
                style={{height:'70px', width:'70px', borderRadius:'50%', backgroundColor:'#ff5757', color:'white', marginRight: '10px', boxShadow: '1px 20px 40px -10px  #ff5757', display:'inline-block', textAlign:'center', cursor:'pointer'}}
              >
                <Grid container justify="center" alignItems="center" style={{height:'100%', width:'100%'}}>
                    <Clear style={{fontSize:'45px'}}/>
                </Grid>
              </div>
              <div 
                onClick={() => onSwipe(0, matches[0].Id, getTypeOfSalesforceRecord(matches[0]))}
                style={{height:'70px', width:'70px', borderRadius:'50%', backgroundColor:'#3ce8b7', color:'white', marginLeft: '10px', boxShadow: '1px 20px 40px -9px #3ce8b7', display:'inline-block', textAlign:'center', cursor:'pointer' }}
              >
                <Grid container justify="center" alignItems="center" style={{height:'100%', width:'100%'}}>
                    <FavoriteTwoToneIcon style={{fontSize:'45px'}}/>
                </Grid>
              </div>
            </Grid>
            <Grid item xs>
              <IconButton
                aria-label="About"
                size="medium"
                style={{ backgroundColor: 'white', boxShadow: '2px 2px 4px 1px lightgrey' }}
                onClick={() => setShowInstructions(true)}
              >
                <HelpOutlineOutlinedIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
          </Container>
        </div>
      ) : ''}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
      >
        <Alert severity={alertType}>{alertMessage}</Alert>
      </Snackbar>

      <SwipeInstructions open={showInstructions} onClose={() => {setShowInstructions(false)}} />
    </div>
  );

}
